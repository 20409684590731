import * as z from 'zod'
import { StringToDate } from '@digital-magic/react-common/lib/utils/zod'
import {
  AccessInProperty,
  AccessToProperty,
  EnergyClassId,
  FormOfOwnershipId,
  ImmovableNumber,
  ProjectEncumbrances,
  PropertyTypeId
} from '@api/endpoints/settings'
import {
  ApartmentsCount,
  FacadeColor,
  FloorNumber,
  InteriorStyleType,
  LayoutTypeFreeOptionId,
  LayoutTypeId,
  LayoutTypeImages,
  LayoutTypeName,
  LayoutTypeOptionValueId,
  LayoutTypeStyleId,
  LayoutTypeStyleImages
} from '../../buildings/layoutTypes'
import {
  OptionCode,
  OptionId,
  OptionName,
  OptionValueDescription,
  OptionValueId,
  OptionValueImages,
  OptionValueName
} from '../../buildings/options'
import { CreateBasicImageRequest, ImageFileEntity, TranslatedFileId } from '../../files'
import {
  AllowedAction,
  PositiveDecimal,
  PositiveDecimalNumber,
  PositiveInt,
  Price,
  PriceNumber,
  ShortDescription,
  TranslatedString,
  UrlString
} from '../../types'
import { BuildingType } from '../buildingTypes'

export const HouseStatus = z.enum(['Available', 'Booked', 'Sold', 'Disabled'])
export type HouseStatus = z.infer<typeof HouseStatus>

export const ImageVisibility = z.enum(['Booking', 'Deal', 'Any'])
export type ImageVisibility = z.infer<typeof ImageVisibility>

export const AddressGroup = z.enum(['all', 'assigned', 'available'])
export type AddressGroup = z.infer<typeof AddressGroup>

export const BuildingId = z.string().uuid()
export type BuildingId = z.infer<typeof BuildingId>

export const HouseId = z.string().uuid()
export type HouseId = z.infer<typeof HouseId>

export const HouseStyleExceptionId = z.string().uuid()
export type HouseStyleExceptionId = z.infer<typeof HouseStyleExceptionId>

export const HouseOptionValueExceptionId = z.string().uuid()
export type HouseOptionValueExceptionId = z.infer<typeof HouseOptionValueExceptionId>

export const BuildingAddress = z.string().min(4).max(50)
export type BuildingAddress = z.infer<typeof BuildingAddress>

export const BuildingAddress2 = z.string().min(4).max(50)
export type BuildingAddress2 = z.infer<typeof BuildingAddress2>

export const BuildingAddressList = z.array(BuildingAddress)
export type BuildingAddressList = z.infer<typeof BuildingAddressList>

export const HouseCode = z.string().min(3).max(10)
export type HouseCode = z.infer<typeof HouseCode>

export const CadastreNumber = z.string().min(5).max(15)
export type CadastreNumber = z.infer<typeof CadastreNumber>

export const RoomsCount = z.number().int().min(1).max(10)
export type RoomsCount = z.infer<typeof RoomsCount>

export const ApartmentNumber = z.string().min(1).max(4)
export type ApartmentNumber = z.infer<typeof ApartmentNumber>

export const BookingItemId = z.string().uuid()
export type BookingItemId = z.infer<typeof BookingItemId>

export const BookingItemSlug = z.string().min(10).max(100)
export type BookingItemSlug = z.infer<typeof BookingItemSlug>

export const BuildingPermit = TranslatedString(ShortDescription)
export type BuildingPermit = z.infer<typeof BuildingPermit>

export const UsagePermit = TranslatedString(ShortDescription)
export type UsagePermit = z.infer<typeof UsagePermit>

export const Building = z.object({
  id: BuildingId,
  layoutTypeId: LayoutTypeId,
  facadeColor: FacadeColor,
  address: BuildingAddress,
  address2: BuildingAddress2,
  outdoorSquare: PositiveDecimal,
  indoorSquare: PositiveDecimal,
  storeRoomSquare: PositiveDecimal,
  terraceSquare: PositiveDecimal,
  roomsCount: RoomsCount,
  bedroomsCount: RoomsCount,
  hasSauna: z.boolean(),
  isInteriorIncluded: z.boolean(),
  isSanitaryIncluded: z.boolean(),
  energyClassId: EnergyClassId.optional(),
  buildingPermit: BuildingPermit,
  usagePermit: UsagePermit,
  accessToProperty: AccessToProperty.optional(),
  accessInProperty: AccessInProperty.optional(),
  formOfOwnershipId: FormOfOwnershipId.optional(),
  propertyTypeId: PropertyTypeId.optional(),
  encumbrances: ProjectEncumbrances.optional(),
  basePrice: Price,
  allowedActions: z.array(AllowedAction),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type Building = z.infer<typeof Building>

export const BuildingListItem = z.object({
  id: BuildingId,
  layoutTypeId: LayoutTypeId,
  facadeColor: FacadeColor,
  address: BuildingAddress,
  roomsCount: RoomsCount,
  basePrice: Price,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type BuildingListItem = z.infer<typeof BuildingListItem>

export const BuildingList = z.array(BuildingListItem)
export type BuildingList = z.infer<typeof BuildingList>

export const UpdateBuildingRequest = z.object({
  facadeColor: FacadeColor,
  address2: BuildingAddress2,
  outdoorSquare: PositiveDecimalNumber,
  indoorSquare: PositiveDecimalNumber,
  storeRoomSquare: PositiveDecimalNumber,
  terraceSquare: PositiveDecimalNumber,
  roomsCount: RoomsCount,
  bedroomsCount: RoomsCount,
  hasSauna: z.boolean(),
  isInteriorIncluded: z.boolean(),
  isSanitaryIncluded: z.boolean(),
  buildingPermit: BuildingPermit,
  usagePermit: UsagePermit,
  energyClassId: EnergyClassId,
  accessToProperty: AccessToProperty.optional(),
  accessInProperty: AccessInProperty.optional(),
  formOfOwnershipId: FormOfOwnershipId,
  propertyTypeId: PropertyTypeId,
  encumbrances: ProjectEncumbrances.optional(),
  basePrice: PriceNumber
})
export type UpdateBuildingRequest = z.infer<typeof UpdateBuildingRequest>

export const CreateBuildingRequest = UpdateBuildingRequest.extend({
  layoutTypeId: LayoutTypeId,
  address: BuildingAddress
})
export type CreateBuildingRequest = z.infer<typeof CreateBuildingRequest>

export const House = z.object({
  id: HouseId,
  code: HouseCode,
  buildingId: BuildingId,
  status: HouseStatus,
  cadastreNumber: CadastreNumber,
  apartmentNumber: ApartmentNumber.optional(),
  floor: FloorNumber.optional(),
  outdoorSquare: PositiveDecimal.optional(),
  indoorSquare: PositiveDecimal.optional(),
  storeRoomSquare: PositiveDecimal.optional(),
  terraceSquare: PositiveDecimal.optional(),
  roomsCount: RoomsCount.optional(),
  bedroomsCount: RoomsCount.optional(),
  hasSauna: z.boolean().optional(),
  isInteriorIncluded: z.boolean().optional(),
  isSanitaryIncluded: z.boolean().optional(),
  energyClassId: EnergyClassId.optional(),
  immovableNumber: ImmovableNumber.optional(),
  basePrice: Price.optional(),
  allowedActions: z.array(AllowedAction),
  handoffAt: StringToDate.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type House = z.infer<typeof House>

export const HouseListItem = z.object({
  id: HouseId,
  buildingId: BuildingId,
  code: HouseCode,
  status: HouseStatus,
  cadastreNumber: CadastreNumber,
  apartmentNumber: ApartmentNumber.optional(),
  floor: FloorNumber.optional(),
  roomsCount: RoomsCount.optional(),
  basePrice: Price.optional(),
  handoffAt: StringToDate.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type HouseListItem = z.infer<typeof HouseListItem>

export const HouseList = z.array(HouseListItem)
export type HouseList = z.infer<typeof HouseList>

export const UpdateHouseRequest = z.object({
  code: HouseCode,
  cadastreNumber: CadastreNumber,
  apartmentNumber: ApartmentNumber.optional(),
  floor: FloorNumber.optional(),
  outdoorSquare: PositiveDecimalNumber.optional(),
  indoorSquare: PositiveDecimalNumber.optional(),
  storeRoomSquare: PositiveDecimalNumber.optional(),
  terraceSquare: PositiveDecimalNumber.optional(),
  roomsCount: RoomsCount.optional(),
  bedroomsCount: RoomsCount.optional(),
  hasSauna: z.boolean().optional(),
  isInteriorIncluded: z.boolean().optional(),
  isSanitaryIncluded: z.boolean().optional(),
  energyClassId: EnergyClassId.optional(),
  immovableNumber: ImmovableNumber,
  basePrice: PriceNumber.optional(),
  handoffAt: z.date().optional()
})
export type UpdateHouseRequest = z.infer<typeof UpdateHouseRequest>

export const CreateHouseRequest = UpdateHouseRequest.extend({
  buildingId: BuildingId
})
export type CreateHouseRequest = z.infer<typeof CreateHouseRequest>

export const UpdateHouseStatusRequest = z.object({
  status: HouseStatus
})
export type UpdateHouseStatusRequest = z.infer<typeof UpdateHouseStatusRequest>

export const HousePlanImage = ImageFileEntity.extend({
  houseId: HouseId,
  visibility: ImageVisibility
})
export type HousePlanImage = z.infer<typeof HousePlanImage>

export const HousePlanImageListItem = ImageFileEntity.extend({
  visibility: ImageVisibility
})
export type HousePlanImageListItem = z.infer<typeof HousePlanImageListItem>

export const HousePlanImages = HousePlanImageListItem.array()
export type HousePlanImages = z.infer<typeof HousePlanImages>

export const UpdateHousePlanImageVisibilityRequest = z.object({
  visibility: ImageVisibility
})
export type UpdateHousePlanImageVisibilityRequest = z.infer<typeof UpdateHousePlanImageVisibilityRequest>

export const CreateHousePlanImageRequest = CreateBasicImageRequest.extend(UpdateHousePlanImageVisibilityRequest.shape)
export type CreateHousePlanImageRequest = z.infer<typeof CreateHousePlanImageRequest>

export const HouseStyleException = z.object({
  id: HouseStyleExceptionId,
  houseId: HouseId,
  layoutTypeStyleId: LayoutTypeStyleId,
  additionalPrice: Price,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type HouseStyleException = z.infer<typeof HouseStyleException>

export const HouseStyleExceptions = HouseStyleException.array()
export type HouseStyleExceptions = z.infer<typeof HouseStyleExceptions>

export const CreateHouseStyleExceptionRequest = z.object({
  layoutTypeStyleId: LayoutTypeStyleId,
  additionalPrice: PriceNumber
})
export type CreateHouseStyleExceptionRequest = z.infer<typeof CreateHouseStyleExceptionRequest>

export const HouseOptionValueException = z.object({
  id: HouseOptionValueExceptionId,
  houseId: HouseId,
  layoutTypeOptionValueId: LayoutTypeOptionValueId,
  price: Price.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type HouseOptionValueException = z.infer<typeof HouseOptionValueException>

export const HouseOptionValueExceptions = HouseOptionValueException.array()
export type HouseOptionValueExceptions = z.infer<typeof HouseOptionValueExceptions>

export const UpdateHouseOptionValueExceptionRequest = z.object({
  price: PriceNumber.optional()
})
export type UpdateHouseOptionValueExceptionRequest = z.infer<typeof UpdateHouseOptionValueExceptionRequest>

export const CreateHouseOptionValueExceptionRequest = UpdateHouseOptionValueExceptionRequest.extend({
  layoutTypeOptionValueId: LayoutTypeOptionValueId
})
export type CreateHouseOptionValueExceptionRequest = z.infer<typeof CreateHouseOptionValueExceptionRequest>

export const DeleteHouseOptionValueExceptionRequest = z.object({
  houseId: HouseId,
  houseOptionValueExceptionId: HouseOptionValueExceptionId
})
export type DeleteHouseOptionValueExceptionRequest = z.infer<typeof DeleteHouseOptionValueExceptionRequest>

// Views

export const HouseStyleView = z.object({
  layoutTypeStyleId: LayoutTypeStyleId,
  styleType: InteriorStyleType,
  additionalPrice: Price,
  documentId: TranslatedFileId.optional(),
  images: LayoutTypeStyleImages,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type HouseStyleView = z.infer<typeof HouseStyleView>

export const HouseFreeOptionView = z.object({
  layoutTypeFreeOptionId: LayoutTypeFreeOptionId,
  optionId: OptionId,
  disabled: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate,
  code: OptionCode,
  name: TranslatedString(OptionName),
  multiValue: z.boolean()
})
export type HouseFreeOptionView = z.infer<typeof HouseFreeOptionView>

export const HouseOptionValueView = z.object({
  layoutTypeOptionValueId: LayoutTypeOptionValueId,
  exceptionId: HouseOptionValueExceptionId.optional(),
  optionValueId: OptionValueId,
  price: Price,
  disabled: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate,
  name: TranslatedString(OptionValueName),
  images: OptionValueImages,
  description: TranslatedString(OptionValueDescription).optional(),
  documentId: TranslatedFileId.optional(),
  orderNumber: PositiveInt
})
export type HouseOptionValueView = z.infer<typeof HouseOptionValueView>

export const HouseOptionView = z.object({
  optionId: OptionId,
  disabled: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate,
  code: OptionCode,
  name: TranslatedString(OptionName),
  multiValue: z.boolean(),
  orderNumber: PositiveInt,
  values: z.array(HouseOptionValueView)
})
export type HouseOptionView = z.infer<typeof HouseOptionView>

export const BookingItem = z.object({
  id: BookingItemId,
  slug: BookingItemSlug,
  code: HouseCode,
  address: BuildingAddress,
  apartmentNumber: ApartmentNumber.optional(),
  apartmentsCount: ApartmentsCount.optional(),
  basePrice: Price,
  buildingId: BuildingId,
  buildingType: BuildingType,
  cadastreNumber: CadastreNumber,
  facadeColor: FacadeColor,
  floorsCount: FloorNumber,
  indoorSquare: PositiveDecimal,
  layoutTypeId: LayoutTypeId,
  layoutTypeName: LayoutTypeName,
  outdoorSquare: PositiveDecimal,
  roomsCount: RoomsCount,
  status: HouseStatus,
  storeRoomSquare: PositiveDecimal,
  terraceSquare: PositiveDecimal,
  handoffAt: StringToDate.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type BookingItem = z.infer<typeof BookingItem>

export const BookingItems = BookingItem.array()
export type BookingItems = z.infer<typeof BookingItems>

export const BuildingView = z.object({
  id: BuildingId,
  type: BuildingType,
  address: BuildingAddress,
  address2: BuildingAddress2,
  facadeColor: FacadeColor,
  buildingPermit: BuildingPermit,
  usagePermit: UsagePermit,
  accessToProperty: AccessToProperty.optional(),
  accessInProperty: AccessInProperty.optional(),
  formOfOwnershipId: FormOfOwnershipId.optional(),
  propertyTypeId: PropertyTypeId.optional(),
  encumbrances: ProjectEncumbrances.optional(),
  layoutTypeId: LayoutTypeId,
  floorsCount: FloorNumber,
  apartmentsCount: ApartmentsCount.optional()
})
export type BuildingView = z.infer<typeof BuildingView>

export const BookingItemView = z.object({
  id: HouseId,
  code: HouseCode,
  status: HouseStatus,
  building: BuildingView,
  cadastreNumber: CadastreNumber,
  apartmentNumber: ApartmentNumber.optional(),
  floor: FloorNumber.optional(),
  outdoorSquare: PositiveDecimal,
  indoorSquare: PositiveDecimal,
  storeRoomSquare: PositiveDecimal,
  terraceSquare: PositiveDecimal,
  roomsCount: RoomsCount,
  bedroomsCount: RoomsCount,
  hasSauna: z.boolean(),
  isInteriorIncluded: z.boolean(),
  isSanitaryIncluded: z.boolean(),
  energyClassId: EnergyClassId.optional(),
  immovableNumber: ImmovableNumber.optional(),
  planImages: HousePlanImages,
  images: LayoutTypeImages,
  freeOptions: z.array(HouseFreeOptionView),
  handoffAt: StringToDate.optional(),
  styles: z.array(HouseStyleView),
  options: z.array(HouseOptionView),
  basePrice: Price,
  virtualTourUrl: UrlString.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type BookingItemView = z.infer<typeof BookingItemView>
